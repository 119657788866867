<script setup>
const props = defineProps({
    processing: {
      type: Boolean,
      default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

</script>

<template>
    <button :class="{ 'pointer-events-none': props.processing }" :disabled="props.disabled">
        <span v-if="props.processing" class="loading loading-spinner loading-xs"></span>
        <slot></slot>
    </button>
</template>

<style scoped>

</style>
